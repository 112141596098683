$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);

$grey1000: #19191A;
$grey900: #1D1D1F;
$grey800: #353538;
$grey500: #82828b;
$grey400: #989898;

$t1: .3s ease-in-out;
$t2: .5s ease-in-out;
$t3: 1s ease-in-out;



// hasDesign hasDev hasCom hasStrat
// askedSpecs
// client : 6LdhXJwpAAAAAFWUhY8c0etIPvEK66z03gLEFVnv
// other : 6LdhXJwpAAAAAJKWkIpygyaoGrHK__wrEfBTJkKk