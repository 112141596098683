@import "../variables.scss";

.clients {
  display: flex;
  flex-direction: column;
  color: $white;
  text-align: left;
  padding-top: 8rem;
  padding-bottom: 5rem;

  .row {
    display: flex;
    padding: 0.5rem 5rem;
    width: 100%;

    > .tile:first-of-type {
      margin-right: 0.5rem;
    }
    > .tile:last-of-type {
      margin-left: 0.5rem;
    }
  }

  .subCol {
    h1 {
      font-size: 6rem;
      margin-bottom: 5rem;
      font-weight: 600;
      line-height: 130%;
      text-transform: uppercase;

      > b {
        display: block;
        font-size: 11rem;
        font-weight: 700;
      }
    }

    &:first-child {
      margin-right: 0.5rem;
      flex: 3;
    }
    &:last-child {
      margin-left: 0.5rem;
      flex: 2;
      display: flex;
      flex-direction: column;
    }
  }

  .tile {
    border-radius: 2rem;
    display: flex;
    align-items: flex-end;
    height: calc(13rem + 20vw);
    background-color: $grey800;
    flex: 2;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    position: relative;
    transform: translateZ(0);
    isolation: isolate;

    .mobilePlus {
      display: none;
    }

    > .title {
      border-top-right-radius: 1rem;
      padding: 1rem 1.7rem 1.2rem;
      background-color: rgba($black, 0.5);
      backdrop-filter: blur(24px);
      text-transform: uppercase;
      min-width: 10rem;
      text-align: center;
      transform: translateZ(0);
      transition: 0.5s linear transform;

      > small {
        opacity: 0;
      }
    }

    &.larger {
      flex: 4;
    }

    &.border {
      border: 1px solid $grey800;
    }

    &.video {
      flex: 2;
      position: relative;

      > video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translateZ(0);
        transition: 0.5s linear transform;
      }

      > .title {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 10;
      }
    }

    .hover {
      opacity: 0;
      transition: $t1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: rgba($black, 0.7);
      z-index: 3;

      > div {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $white;
        text-transform: uppercase;

        small {
          font-size: 2rem;
          margin-bottom: 0.5rem;
          font-weight: 500;
        }

        b {
          font-size: 4rem;
          font-weight: 700;
          text-align: center;
        }

        a {
          color: $white;
          text-align: center;
        }

        &:nth-child(2),
        &:nth-child(3) {
          border-top: 1px solid rgba($grey500, 0.7);
        }
      }
    }

    &:hover .hover {
      opacity: 1;
    }
  }
}

@media (max-width: 1440px) {
  .clients {
    .tile {
      .hover {
        > div {
          small {
            font-size: 1.5rem;
          }

          b {
            font-size: 3rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .clients {
    .subCol {
      &:first-child,
      &:last-child {
        margin-right: 0;
        margin-left: 0;
      }

      h1 {
        font-size: clamp(3rem, 6vw, 5rem);
        margin-bottom: 10vh;
        line-height: 140%;
        text-align: center;

        > b {
          font-size: 270%;
        }
      }
    }

    .row {
      flex-direction: column;
      margin: 0;
      padding: 0 1.5rem;

      > .tile {
        &:first-child,
        &:last-child {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }

    .tile {
      width: 100%;
      min-height: 25rem;
      height: 25rem;
      max-height: 25rem;
      margin-top: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: unset;
      border-radius: 1.5rem;
      margin-top: 0.7rem;

      > .title {
        //flex-direction: column;
        margin-top: 21rem;
        border-radius: 0;
        height: 4rem;
        padding: 0 1.5rem;
        display: flex;
        align-items: center;
        width: 100%;
        text-align: left;
        font-size: 1.1rem;
        font-weight: 500;
        transition: $t1;

        > small {
          opacity: 0;
          font-size: 1.1rem;
          margin-top: 0.4rem;
          font-weight: 400;
          transition: ease-in-out 1s;
        }
      }

      .mobilePlus {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($grey800, 0.4);
        backdrop-filter: blur(24px);
        padding: 1rem;
        border-radius: 100%;
        position: absolute;
        right: 1rem;
        bottom: 0.6rem;
        z-index: 10;
        transition: $t1;
        transform: rotate(45deg);

        > svg {
          height: 0.75rem;
          width: 0.75rem;
        }
      }

      .hover {
        height: calc(100% - 5rem);
        border-top: 1px solid $white;
        top: 5rem;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: unset;
        z-index: 5;

        > div {
          flex: 0;
          align-items: flex-start;
          padding: 2rem;
          width: 100%;

          small {
            font-size: 1rem;
          }

          b {
            font-size: 1.7rem;
            font-weight: 500;
          }
        }
      }

      &:hover {
        /*.title {
          opacity: 0;
        }*/
        .hover {
          opacity: 0;
        }
      }

      &.hoveredMobile {
        //:hover {

        .title {
          height: 100%;
          align-items: flex-start;
          padding-top: 1.5rem;
          font-size: 2rem;
          font-weight: 600;
          justify-content: space-between;
          margin-top: 0;
          position: absolute;
          opacity: 1;

          > small {
            opacity: 1;
          }
        }

        .hover {
          height: calc(100% - 5rem);
          border-top: 1px solid rgba($white, 0.2);
          top: 5rem;
          z-index: 10;
          opacity: 1;
        }

        .mobilePlus {
          transform: rotate(0deg);
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .clients {
    padding-top: 3rem;
  }
}

@media (max-width: 678px) {
  .clients {
    .tile {
      min-height: 17rem;
      height: 17rem;
      max-height: 17rem;

      .title {
        margin-top: 13rem;
      }

      .mobilePlus {
        padding: 0.7rem;
        bottom: 1rem;
      }

      &.hoveredMobile {
        .title {
          padding: 1.5rem 1.5rem;
          font-size: 1.3rem;
          font-weight: 700;

          > small {
            margin-top: 0.15rem;
            font-size: 1rem;
          }
        }

        .hover {
          height: calc(100% - 4rem);
          top: 4rem;
          padding-left: 1rem;

          > div {
            padding: 0.8rem 1rem;

            > b {
              font-size: 1.3rem;
            }

            a > b {
              font-size: 1.3rem;
            }

            > small {
              font-size: 1.3rem;
            }
          }
        }
      }
    }

    .subCol h1 {
      font-size: clamp(1.5rem, 6vw, 3rem);
      margin-bottom: 10vh;
      line-height: 150%;

      > b {
        margin-top: 0.3rem;
        //font-size: 10rem;
      }
    }
  }
}

@media (max-width: 444px) {
  .clients {
    .tile {
      min-height: 13rem;
      height: 13rem;
      max-height: 13rem;
      border-radius: 1rem;

      .title {
        margin-top: 10rem;
        height: 3rem;
        font-size: 1rem;
      }

      .mobilePlus {
        bottom: 0.5rem;
        padding: 0.6rem;
      }

      &.hoveredMobile {
        .title {
          padding: 1rem 1.5rem;
        }

        .hover {
          height: calc(100% - 3.5rem);
          top: 3.5rem;

          > div {
            padding: 0.8rem 1rem;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-end;
            flex-direction: row-reverse;

            & > small {
              margin-left: 0.5rem;
            }
          }
        }
      }
    }

    .subCol h1 {
      //font-size: clamp(3rem, 6vw, 5rem);
      margin-bottom: 7vh;
      margin-top: 5vh;
    }

    .row {
      padding: 0 1rem;
    }
  }
}
