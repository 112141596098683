@import "../variables.scss";





.navTogglerFrame {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 220;

  .navToggler {
    position: absolute;
    bottom: 1rem;
    display: flex;
    align-items: center;
    padding: .5rem .8rem .5rem .8rem;


    .navTogglerBg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($grey900, .25);
      backdrop-filter: blur(25px);
      border: 1px solid $grey800;
      border-radius: 1.5rem;
      z-index: -1;
      
      &.bgAnim {
        border: none;
        background-color: $grey1000;//rgba($grey1000, .75);
      }
    }

    > div:nth-child(2) {
      width: 8rem;
      height: 100%;
      
      > a {
        width: 100%;
        height: 100%;
        display: block;
  
        > img {
          height: 1.9rem;
          width: auto;
        }
      }
    }

    > div:nth-child(3) {
      padding: 1rem;
      margin: 0 1rem;
    }

    > div:last-child {
      width: 8rem;
      
      > a {
        background-color: $white;
        //width: 10rem;
        color: $grey800;
        display: block;
        padding: 1rem 1rem;
        font-weight: 500;
        font-size: .8rem;
        text-transform: uppercase;
        border-radius: 1rem;
      }
    }
  }
}


@keyframes appearbg {
  from {
    left: 30%;
    width: 40%;
    height: 40%;
    top: 80%;
    opacity: 0;
  }
  to {
    left: 0%;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 1;
  }
}

@keyframes appearCover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animCover {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: $black;
  animation: appearCover .2s linear;
  animation-fill-mode: forwards;
}

.menu {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: $black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 210;
  //animation: appearbg .5s ease-out;
  //animation-fill-mode: forwards;


  > nav {
    position: fixed;
    top: 6rem;
    right: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 201;

    a {
      text-align: right;
      color: $white;
      padding: 1rem;
      font-size: 3rem;
      font-weight: 500;
      text-transform: uppercase;
      transition: $t1 "font-style";

      &:hover {
        //color: $grey500;
        font-style: italic;
        font-weight: 700;
      }
    }
  }


  .top {
    display: flex;
    justify-content: space-between;
    padding: 6rem 5rem;
    width: 100%;

    > a:first-child {
    
      > svg {
        height: 9rem;
        width: auto;
        path, rect { transition: $t1; }
      }

      &:hover {
        svg path, svg rect {
          fill: $grey500;
        }
      }
    }
  }

  .bottom {
    display: flex;
    padding-left: 5rem;
    padding-bottom: 10rem;
    align-items: flex-end;

    div {
      display: flex;

      a {
        font-size: 6rem;
        text-align: left!important;
        font-weight: 600;
        line-height: 120%;
        text-transform: uppercase;
        color: $white;
  
        &:hover {
          color: $grey500;
          transition: $t1;
        }
  
        b {
          text-align: left;
          font-weight: 500;
          display: block;
          font-size: 11rem;
          font-weight: 700;
        }
      }
    }


    .line {
      margin-left: -2.5rem;
      flex: 2;
      height: 0.5px;
      margin-bottom: -.8rem;
      z-index: -1;
      background-color: $grey1000;
    }
  }
}





@media (max-width: 1000px) {

  .navTogglerFrame {
    //min-height: 40vh;
    background-color: rgba($grey900, .35);
    backdrop-filter: blur(35px);
    border-top: .5px solid rgba($grey500, .5);
    flex-direction: column;

    nav {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      flex: 2;
      padding: 2rem 3rem 6rem;
      transition: $t1;

      a {
        font-size: 2.3rem;
        color: $white;
        text-transform: uppercase;
        margin: 1rem 0;
      }
    }
    
    &.mobileOpen {
      .navToggler {
         > div:nth-child(3) {
          top: calc(100% - 4rem);
        }

        > div:nth-child(2) {
          > a {
            > img {
              height: 2.9rem;
            }
          }
        }
      }
    }
  
    .navToggler {
      position: static;
      display: flex;
      align-items: center;
      padding: 1.5rem;
      width: 100%;
      border: none;
      background: transparent;
      border: none;
      justify-content: space-between;
      transition: $t1;
  
      .navTogglerBg {
        display: none;
      }

      > div:nth-child(1) {
        display: none;
      }
  
      > div:nth-child(2) {
        width: unset;
        
        > a {
          width: unset;
          > img {
            height: 2.5rem;
            transition: $t1;
          }
        }
      }
  
      > div:nth-child(3) {
        height: 3rem;
        width: 3rem;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: ease-out .5s;

        position: fixed;
        left: calc(50% - 1.5rem);
        top: 1.5rem;
      }
  
      > div:last-child {
        width: 8rem;
        
        > a {
          background-color: $white;
          padding: 1rem 1rem;
          font-size: .8rem;
          border-radius: 1rem;
        }
      }
    }
  }



}