@import "../variables.scss";

.splash {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    left: 0;
    top: 0;
    background-color: $black;
    z-index: 1000;
    
    video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

/*@media (max-width: 678px) {
    .splash {
        video {
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transform: none;
        }
    }
}*/