@import "../variables.scss";

footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: $white;
  font-size: 1rem;
  padding: 2rem 5rem 3rem;
  text-transform: uppercase;

  .left {
    display: flex;
    flex: 2;
    text-align: left;

    > span {
      flex: auto;
      margin-right: 1.5rem;
    }

    > a {
      color: $white;
      margin-right: 1.5rem;
    }

    &.mobile {
      display: none;
    }
  }

  .center {
    width: 20rem;
  }

  .right {
    flex: 2;
    display: flex;
    text-align: right;

    > a {
      flex: auto;
      margin-left: 2rem;
      color: $white;
    }
  }

  .langFrame {
    position: relative;
    padding: 1rem 3rem;
  }

  .lang {
    border: 1px solid $grey800;
    border-radius: 1rem;
    background: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1rem;
    flex-direction: column-reverse;
    position: absolute;
    left: 2rem;
    bottom: 0.2rem;

    .main {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem 0.7rem 1rem;

      svg {
        height: 0.8rem;
        width: 0.8rem;
        margin-left: 0.5rem;
        transition: $t1;
        transform: rotate(270deg);

        path {
          fill: $grey800;
        }
      }
    }

    .choice {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 0px;
      transition: $t1;
      overflow: hidden;
      width: 100%;

      span {
        padding: 0.5rem 1rem;
        width: 100%;
        text-align: left;

        &:hover {
          font-weight: 600;
        }
      }
    }

    &.open {
      height: 5rem;

      .main {
        svg {
          transform: rotate(90deg) translateX(0.2rem);
        }
      }

      .choice {
        padding-top: 0.5rem;
        height: 3rem;
      }
    }
  }
}

@media (max-width: 1000px) {
  footer {
    flex-direction: column;
    padding-bottom: 10rem;
    align-items: center;

    > .left,
    > .right {
      flex-direction: column;
      align-items: center;
    }

    > .left {
      text-align: center;

      span {
        margin: 0;
        margin-top: 1rem;

        &:first-of-type {
          display: none;
        }
      }

      &.mobile {
        display: block;
        margin-top: 3rem;
      }
    }

    > .right {
      margin-top: 3rem;

      a {
        margin: 0;
        margin-top: 1rem;
      }
    }

    .langFrame {
      position: relative;
      padding: 3rem 0 0;
    }

    .lang {
      flex-direction: column;
      position: static;

      .main {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem 0.7rem 1rem;

        svg {
          height: 0.8rem;
          width: 0.8rem;
          margin-left: 0.5rem;
          transition: $t1;
          transform: rotate(270deg);

          path {
            fill: $grey800;
          }
        }
      }

      .choice {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 0px;
        transition: $t1;
        overflow: hidden;
        width: 100%;

        span {
          padding: 0.5rem 1rem;
          width: 100%;
          text-align: left;

          &:hover {
            font-weight: 600;
          }
        }
      }

      &.open {
        height: 5.3rem;

        .choice {
          padding-top: 0.5rem;
          height: 5rem;
        }
      }
    }
  }
}
