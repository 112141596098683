@import "../variables.scss";

.landing {
  color: $white;

  .hero {
    width: 100%;
    height: 100vh;
    position: relative;

    > .layer {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    > video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .weAre.ghostForScroll {
    position: static;
    flex-direction: column;
    height: auto;

    > .slider > .slide {
      background-color: transparent;
      color: $black;
      z-index: -1;
      opacity: 0;
    }
  }

  .weAre {
    width: 100%;
    height: 100vh;
    display: flex;
    position: sticky;
    top: 3rem;
    margin-top: 3rem;
    z-index: 3;

    .controlChevron {
      position: absolute;
      bottom: 25%;
      right: 10%;
      height: 3.5rem;
      width: 3.5rem;
      background-color: $grey500;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 200;
      transition: $t1;

      &.reverse {
        transform: rotate(180deg);
      }

      &.grey {
        background-color: $white;
        > svg path {
          fill: $grey500;
        }
      }

      > svg {
        transform: rotate(180deg) translateX(0.2rem);
        height: 2rem;
        width: 2rem;
      }
    }

    .animSliderFrame {
      position: relative;
      overflow: hidden;
    }

    .sliderContainer {
      position: relative;
      overflow: hidden;
      min-width: 100vw; //calc(100vw - 5rem);

      .sliderContainer {
        min-width: 100vw; //calc(100vw - 5rem);

        .slide {
          width: calc(100vw - 7rem);
          min-width: calc(100vw - 7rem);
          margin-left: 0.5rem;
        }
      }
    }

    .slider {
      display: flex;
      width: 100%;
      position: relative;
      //padding: 5rem 0;

      .slide {
        height: 80vh;
        width: calc(100vw - 7rem);
        min-width: calc(100vw - 7rem);
        background-color: $grey500;
        margin: 0 0.5rem;
        margin-left: 2rem;
        border-radius: 1.5rem;
        text-transform: uppercase;
        text-align: left;
        padding: 4rem 3.5rem;
        line-height: 85%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: $t1;

        &.snapped {
          opacity: 0;
        }

        &.main {
          font-size: clamp(7rem, 10vw, 13rem);
          font-weight: 800;
          color: $black;
          justify-content: flex-end;
          background-color: $white;
          //left: 0;

          &.onBg {
            //position: absolute;
            //left: calc(100vw - 5rem);
            background-color: $grey500;
            transform: scale(0.9);
          }
        }

        &.desc {
          justify-content: center;
          font-size: clamp(1.7rem, 2.3vw, 3rem);
          font-weight: 600;
          line-height: 110%;

          > b {
            color: $black;
            margin-bottom: 3rem;

            > span {
              color: $white;
            }
          }

          &.onTop {
            transform: translateX(-100%);
            z-index: 1;
            transition: $t3;
          }
        }

        &.large {
          justify-content: center;
          font-size: clamp(6rem, 9vw, 12rem); //6.5rem;
          font-weight: 700;

          b.fr {
            font-size: clamp(5.5rem, 8vw, 11rem); 
          }

          b span {
            color: $black;
          }
        }
      }
    }
  }

  .services {
    margin: 5rem 0;

    .expect {
      padding: 2rem 5rem;

      .head {
        text-align: left;
        position: sticky;
        top: 0;
        background-color: $black;
        height: 40vh;
        padding-bottom: 5vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        z-index: 10;
        width: 100%;

        h3 {
          font-size: 3rem;
          margin: 0 0 2rem;
          font-weight: 600;
        }

        div {
          overflow: hidden;
          //display: flex;
          //flex-wrap: wrap;

          > span {
            //margin-left: .5rem;
            font-size: 1.2rem;
            color: $grey500;
            //display: block;
            //max-width: 100%;
          }
        }
      }

      .cards {
        display: flex;
        padding: 4rem 0;
        //flex-wrap: wrap;

        .cardLine {
          height: 150vh;
          padding-right: 2rem;
          flex: 2;

          &:last-child {
            padding-right: 0;
          }

          .card {
            position: sticky;
            top: 40vh;
            margin-top: 10vh;
          }

          &.two .card {
            margin-top: 50vh;
          }
          &.three .card {
            margin-top: 75vh;
          }
          &.four .card {
            margin-top: 100vh;
          }
        }

        .card {
          background-color: rgba($grey800, 0.5);
          height: 18rem;
          width: 100%; //calc(25vw - 4rem);
          margin: 0.5rem 0.5rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 2rem 2.5rem;
          border-radius: 1rem;
          text-align: left;
          color: $white;

          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }

          > svg {
            width: auto;
            height: 4rem;
            margin: 2rem 0;
          }

          > b {
            font-size: 1.2rem;
            line-height: 120%;
            max-width: 80%;
          }
        }
      }
    }

    .scrolledTextFrame {
      width: 100%;
      //overflow: hidden;
      min-height: calc(20rem + 30vh);
    }

    .scrolledText {
      overflow: hidden;
      position: relative;
      padding: 3rem 0;
      width: 100%;
      max-width: 100%;
      margin: 0;
      white-space: nowrap;
      display: flex;
      flex-wrap: nowrap;
      position: sticky;
      top: 10rem;

      .scroller {
        display: flex;
        white-space: nowrap;
        display: flex;
        flex-wrap: nowrap;
      }

      span {
        font-size: 13rem;
        font-weight: 800;
        text-transform: uppercase;
        white-space: nowrap;
        opacity: 0.85;
        color: $white;
        display: block;
      }
    }
  }

  .ghostForScroll {
    height: 400vh;
  }

  .real {
    padding: 5vh 0;
    width: 100%;
    height: 120vh;
    position: sticky;
    top: 0;
    overflow: hidden;

    .togglerFrame {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: auto;
      width: max-content;
      position: relative;
      margin-bottom: 3vh;
      padding: 0 0 0 0.4rem;
      height: 3.3rem;

      > div:first-child {
        position: absolute;
        right: 0;
        top: 0;
        height: 3.3rem;
        min-width: 100%;
        z-index: 1;

        > svg {
          width: auto;
          height: 3.3rem;
        }
      }

      .toggler {
        z-index: 10;
        border-radius: 20px;
        font-weight: 500;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6.7rem;
        height: 3.3rem;
        position: relative;

        .underline {
          position: absolute;
          background-color: $grey800;
          width: calc(100%);
          left: 0;
          top: 0.25rem;
          height: calc(100% - 0.5rem);
          border-radius: 1.2rem;
          z-index: -1;
        }
      }

      .toggler:last-child {
        padding: 0;
        justify-content: flex-end;
        width: 5rem;

        > div {
          height: 3rem;
          width: 3rem;
          padding: 0.4rem 0.5rem;
          margin-right: 0.33rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          transition: $t1;
          background-color: $grey900;

          > svg {
            transform: scale(0.8);
          }
        }

        &.toggler-active {
          > div {
            background-color: $grey800;
            transform: rotate(45deg);

            > svg {
              line {
                stroke: $grey500;
              }
            }
          }
        }
      }
    }

    .swiper {
      overflow: visible;
    }

    .realSlider {
      position: relative;

      .sliderContainer {
        position: relative;
        overflow: hidden;
        min-width: calc(100vw - 10rem);
        padding: 6rem 5rem 0;

        .slider {
          display: inline-flex;
          width: 100%;
          position: relative;
        }
      }

      .swiper {
        width: 100%;
        height: 100%;

        .swiper-slide {
          -webkit-transform: translateZ(0);
          -webkit-backface-visibility: hidden;
          backdrop-filter: blur(3.5px);
          -webkit-backdrop-filter: blur(3.5px);
          -moz-backdrop-filter: blur(3.5px);
        }
      }

      .controlChevron {
        position: absolute;
        bottom: 15%;
        right: 5%;
        height: 3.5rem;
        width: 3.5rem;
        background-color: $grey500;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 200;
        transition: $t1;

        &.reverse {
          transform: rotate(180deg);
        }

        &.grey {
          background-color: $white;
          > svg path {
            fill: $grey500;
          }
        }

        > svg {
          transform: rotate(180deg) translateX(0.2rem);
          height: 2rem;
          width: 2rem;
        }
      }

      .cardFrame {
        height: 100%;
        position: relative;
        padding-top: 4rem;
        overflow: hidden;

        .card {
          width: 100%;
          height: 100%;
          background-color: rgba($grey800, 0.5);
          border-radius: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 3rem 3rem 0.5rem;
          position: relative;

          &.web {
            padding: 3rem 1rem 0 1rem;

            .logo {
              position: static;
              margin-bottom: 2rem;
              height: 4rem;
              width: auto;
              overflow: hidden;
            }
          }

          &.brand {
            /*width: 70vh;
            min-width: 70vh;*/
            height: 0;
            background-color: $grey500;
            padding: 0;
            overflow: hidden;
            touch-action: none;
            background-size: cover;
            background-position: center 10%;
          }
        }

        .logo {
          position: absolute;
          bottom: calc(100% - 2rem);
          height: 4.5rem;
          width: 4.5rem;
        }

        &:hover {
          .frame > img:nth-child(2) {
            opacity: 0;
          }
          .frame video {
            opacity: 1;
            z-index: 0;
          }
        }

        .frame {
          width: 100%;
          pointer-events: none;
          position: relative;
          overflow: hidden;

          > img.shadow {
            width: 100%;
            opacity: 0;
          }

          > img:first-child {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            z-index: 2;
          }

          > img:nth-child(2) {
            width: calc(100% - 1rem);
            height: calc(100% - 1rem);
            position: absolute;
            left: 0.5rem;
            z-index: 1;
          }

          > video {
            width: calc(100% - 1rem);
            height: calc(100% - 1rem);
            object-fit: cover;
            position: absolute;
            left: 0.5rem;
            z-index: -1;
            opacity: 0;
            transition: $t1;
            //-webkit-mask-image: -webkit-radial-gradient(white, black);
            //transform: translateZ(0);
            isolation: isolate;
            //mask-image: radial-gradient(white, black);
            //will-change: transform;
            //display: none;
          }

          &.mac {
            height: 100%;
            flex: 2;

            > img.shadow {
              //width: 100;
              height: 100%;
            }

            > img:first-child {
              height: 100%;
              width: auto;
            }

            > img:nth-child(2) {
              width: calc(100% - 1rem);
              height: auto;
              left: 0.5rem;
              border-radius: 0;
            }

            > video {
              width: calc(100% - 1rem);
              height: auto;
              border-radius: 0;
              left: 0.5rem;
            }
          }
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      z-index: 20;
      //background-color: $grey800;
      height: 2rem;
      width: 2rem;
      //border-radius: 100%;
      transition: $t1;

      &:after {
        font-size: 1.3rem;
        color: $grey500;
        font-weight: 600;
      }
    }

    .swiper-button-next {
      right: 0;
    }
    .swiper-button-prev {
      left: 0;
    }

    .firstSlideMode {
      .swiper-button-prev {
        opacity: 0;
      }
    }

    .shadowRight,
    .shadowLeft {
      position: absolute;
      height: 100%;
      width: 20vw;
      background: linear-gradient(
        90deg,
        rgba($black, 0) 0%,
        rgba($black, 1) 100%
      );
      z-index: 10;
      top: 0;
      right: 0;
      overflow: visible;
      pointer-events: none;
    }

    .shadowLeft {
      background: linear-gradient(
        90deg,
        rgba($black, 1) 0%,
        rgba($black, 0) 100%
      );
      z-index: 10;
      top: 0;
      left: 0;
      right: unset;
      transition: $t1;

      &.hidden {
        opacity: 0;
      }
    }

    .terms {
      font-size: 10rem;
      text-transform: uppercase;
      min-height: 70vh;
      width: 100%;
      position: relative;
      overflow: visible;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 3rem;
        background: linear-gradient(
          180deg,
          rgba($black, 1) 30%,
          rgba($black, 0) 100%
        );
        z-index: 10;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3rem;
        background: linear-gradient(
          0deg,
          rgba($black, 1) 30%,
          rgba($black, 0) 100%
        );
        z-index: 10;
      }

      > div {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
        //opacity: 0;
      }

      /*>&:first-child {
        margin-left: -10rem;
      }
      >:last-child {
        margin-bottom: -5rem;
      }*/
    }
  }

  .featured {
    margin: calc(10rem + 35vh) 0 10rem;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: $black;
    border-top: 1px solid $grey800;

    .headFrame {
      padding: 10rem 0;
    }

    .head {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3rem 10rem;
      position: sticky;
      top: 5rem;

      h2 {
        text-transform: uppercase;
        font-size: 10rem;
        line-height: 80%;
        font-weight: 800;
        margin-bottom: 3rem;
      }

      p {
        color: $grey500;
        font-size: 1rem;
        width: 20rem;
        margin-left: 20rem;
        text-align: left;
        line-height: 140%;
      }
    }

    .cards {
      display: flex;
      flex-direction: column;
      align-items: center;

      .card {
        height: 37vw;
        width: 33vw;
        margin: 0.5rem;
        border-radius: 1.5rem;
        overflow: hidden;
        position: sticky;
        top: calc(50vh - 19vw);
        transition: $t1;
        margin-bottom: 30vh;

        &:first-child {
          //margin-top: 10rem;
          //margin-bottom: 30vh;
        }

        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .card-hover {
          color: #fff;
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: 0%;
          left: 0%;
          background: rgba(0, 0, 0, 0.7);
          text-align: center;
          padding: 10px;
          padding-top: 40%;
          box-sizing: border-box;
          opacity: 0;
          transition: 0.5s;
          align-items: center;
          text-transform: uppercase;
        }
        span {
          font-size: 4rem;
        }
        .card-hover:hover {
          opacity: 1;
        }

        &.pinned/*:nth-of-type(2)*/ {
          transform: scale(0.9);
          opacity: 0.8;
          backdrop-filter: blur(20px);
        }

        &:has(+ .pinned) {
          opacity: 0;
        }
      }
    }
  }
}


/** Safari **/
@supports (background: -webkit-named-image(i)) {
  .landing .real {
    position: static;
    max-height: 100vh;
  }
} 


@media (max-width: 1200px) {
  .landing {
    .services {
      .expect {
        .cards {
          .cardLine {
            padding-right: 1rem;
          }

          .card {
            > b {
              max-width: 100%;
            }
          }
        }
      }
    }

    .featured {
      .headFrame {
        .head {
          h2 {
            font-size: clamp(6rem, 11vw, 10rem);
          }
        }
      }
      .cards {
        .card {
          height: 60vw;
          width: 53vw;
          top: calc(50vh - 30vw);
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .landing {
    .services {
      margin: 3rem 0;

      .expect {
        padding: 2rem 2rem;

        .head {
          padding-bottom: 0;
          height: auto;

          h3 {
            font-size: 1.5rem;
            margin: 0 0 1rem;
          }
          p {
            font-size: 1.4rem;
            color: $grey500;
            white-space: wrap;
            max-width: 100%;
          }
        }

        .cards {
          flex-direction: column;
          padding: 2rem 0;

          .cardLine {
            height: unset;
            padding-right: 0;
            flex: 2;

            &:last-child {
              padding-right: 0;
            }

            .card {
              position: static;
              margin-top: 0;
            }

            &.two .card {
              margin-top: 0;
            }
            &.three .card {
              margin-top: 0;
            }
            &.four .card {
              margin-top: 0;
            }
          }

          .card {
            display: flex;
            height: auto;
            align-items: center;
            width: 100%;
            margin: 0;
            margin-bottom: 1rem;
            padding: 0.5rem 1.5 0.5rem 0.5rem;
            flex-direction: row;

            > svg {
              width: auto;
              width: 4rem;
              min-width: 4rem;
              margin: 0;
              margin-right: 2rem;
            }

            > b {
              font-size: 1.2rem;
              line-height: 120%;
              max-width: 70%;
            }
          }
        }
      }

      .scrolledTextFrame {
        margin-bottom: 30vh;
      }

      .scrolledText {
        span {
          font-size: 8rem;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .landing .real .realSlider {
    width: calc(100% - 3rem);
    margin-left: 1.5rem;

    .shadowRight,
    .shadowLeft {
      width: 15vw;
      right: -1.5rem;
      background: linear-gradient(
        90deg,
        rgba($black, 0) 0%,
        rgba($black, 0.7) 100%
      );
    }

    .shadowLeft {
      background: linear-gradient(
        270deg,
        rgba($black, 0) 0%,
        rgba($black, 0.7) 100%
      );
      display: block;
      left: -1.5rem;
      right: unset;
    }
  }
}

@media (min-width: 679px) {
  .landing .real .realSlider .card .frame {
    > img:first-child {
      top: 0;
    }

    > img:nth-child(2) {
      top: 0.5rem;
    }

    > video {
      top: 0.5rem;
    }
  }
}

@media (max-width: 678px) {
  .landing {
    .hero {
      width: 100%;
      height: 100vh;
      position: sticky;
      top: 0;

      > video {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .weAre {
      min-height: 100vh;
      height: auto;
      display: flex;
      position: static;
      margin-top: 3rem;
      margin-bottom: 5rem;
      z-index: 10;
      isolation: isolate;
      transform: translateY(0);

      .slider {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        background-color: $black;
        border-top-left-radius: 1.3rem;
        border-top-right-radius: 1.3rem;
        padding: 1.5rem 1rem;

        .slide {
          height: 30rem;
          margin: 0;
          margin-bottom: 0.7rem;
          width: 100%;
          min-width: unset;
          border-radius: 1.5rem;
          padding: 2.5rem 2.5rem;
          line-height: 85%;
          overflow: hidden;

          &.main {
            font-size: 5em;
          }

          &.desc {
            justify-content: center;
            font-size: 1.1rem;
            font-weight: 500;
            line-height: 110%;

            > b {
              margin-bottom: 1rem;

              /*> span {
                color: $black;
              }*/
            }
          }

          &.large {
            font-size: 3.7rem;
            line-height: unset;

            b.fr {
              font-size: 80%;
              line-height: 90%;
            }
          }
        }
      }
    }

    .services {
      .expect {
        padding: 5rem 1rem;

        .head {
          position: static;

          h3 {
            font-size: 1.5rem;
            margin: 0 0 1rem;
          }
          p {
            font-size: 1.4rem;
            color: $grey500;
            white-space: wrap;
            max-width: 100%;
          }
        }
      }

      .scrolledTextFrame {
        margin-bottom: 5vh;
        min-height: 50vh;
      }

      .scrolledText {
        span {
          font-size: 5rem;
        }
      }
    }

    .real {
      padding: 2rem 0;

      .togglerFrame {
        margin-top: 0;
      }

      .realSlider {
        height: calc(100vh - 8rem);
        max-height: 39rem;
        width: calc(100% - 3rem);
        margin-left: 1.5rem;

        .sliderContainer {
          min-width: calc(100vw - 3rem);
          padding: 3rem 3rem 0;
        }

        .controlChevron {
          display: none;
        }

        .swiper-button-prev {
          left: -1.5rem;
        }

        .swiper-button-next {
          right: -1.5rem;
        }

        

        .cardFrame {
          padding: 3rem 0 0;
          opacity: .5;
          transition: $t1;

          &.swiper-slide-active{
            opacity: 1;
          }

          .card {
            padding: 3rem 1rem 0;

            &.web {
              justify-content: space-between;
              padding-bottom: 0;

              .logo {
                margin-bottom: 0;
                margin-top: 2rem;
              }
            }

            .logo {
              bottom: calc(100% - 2rem);
              height: 4rem;
              width: 4rem;
            }

            .frame {
              width: 100%;
              height: 100%;
              position: relative;
              bottom: -1rem;
              flex: 2;

              > img:first-child {
                top: 0;
                width: 100%;
                height: auto;
                border-bottom: 100px solid $grey900;
              }

              > img:nth-child(2) {
                left: 0.5rem;
                top: 0.5rem;
                width: calc(100% - 1rem);
                height: auto;
              }

              > video {
                top: 0.5rem;
                height: auto;
                object-fit: contain;
              }

              &.mac {
                height: 65vw;
                min-height: 65vw;
                flex: 0;
              }

              /*&.mac {
  
                > img.shadow {
                  display: none;
                }
    
                > img:first-child {
                  width: 100%;
                  left: 0;
                }
      
                > img:nth-child(2) {
                  width: 100%;
                  left: .5rem;
                }
    
                > video {
                  width: 100%;
                  left: .5rem;
                }
              }*/
            }
          }
        }
      }

      /*.terms {
        font-size: 10rem;
        text-transform: uppercase;
        min-height: 70vh;
        width: 100%;
        position: relative;
        overflow: visible;
  
        > div {
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          white-space: nowrap;
          display: flex;
          justify-content: center;
          align-items: center;
          //opacity: 0;
        }
      }*/
    }

    .featured {
      margin-bottom: 0;

      .headFrame {
        padding: 5rem 0;

        .head {
          padding: 3rem;

          h2 {
            font-size: clamp(4rem, 13vw, 8rem);
            margin-bottom: 2rem;
          }
          p {
            //display: none;
            margin-left: 0;
            width: 90%;
            text-align: center;
          }
        }
      }

      .cards {
        .card {
          height: calc(100vw - 2rem);
          width: calc(100vw - 2rem);
          top: calc(50vh - 49vw);

          > .card-hover {
            opacity: 1;
            width: auto;
            height: auto;
            bottom: unset;
            top: 0;
            border-bottom-right-radius: 1.5rem;
            font-size: 0;
            padding: 1.5rem;
            width: 13rem;
            backdrop-filter: blur(20px);
            background-color: rgba($grey800, 0.3);

            > *:first-child {
              display: none;
            }

            > span {
              font-weight: 500;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .landing {
    .real {
      .realSlider {
        max-height: 35rem;

        .shadowRight,
        .shadowLeft {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .landing {
    .real {
      .realSlider {
        max-height: 33rem;
      }
    }
  }
}

@media (max-width: 500px) {
  .landing {
    .real {
      .togglerFrame {
        transform: scale(0.9);

        > div:first-child {
          max-width: 100%;
        }
      }

      .realSlider {
        padding: 0;
        max-height: 37rem;

        .cardFrame {
          
          .card {
            padding: 3rem 10vw 0;

            .logo {
              height: 5rem;
              width: 5rem;
              bottom: calc(100% - 2.5rem);
            }

            .frame {
              &.active {
                transition: $t1;

                > img:nth-child(2) {
                  opacity: 0;
                }
                > video {
                  opacity: 1;
                  transition:
                    1s linear transform,
                    1s linear z-index;
                  transform: translateY(0px);
                  z-index: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 444px) {
  .landing {
    .weAre {
      .slider {
        .slide {
          height: 25rem;
          padding: 2.5rem 1.5rem;
          line-height: 85%;

          &.main {
            font-size: clamp(3rem, 15vw, 4.5rem);
          }

          &.desc {
            font-size: clamp(0.7rem, 3.5vw, 1.2rem);

            > b {
              margin-bottom: 1rem;
            }
          }

          &.large {
            font-size: clamp(2.2rem, 12vw, 3.5rem);
            line-height: 80%;
          }
        }
      }
    }

    .services {
      .expect {
        .cards {
          .card {
            margin-bottom: 0.7rem;
            padding: 0.5rem 1.5 0.5rem 0.5rem;

            > svg {
              width: auto;
              width: 3rem;
              min-width: 3rem;
            }

            > b {
              font-size: 1.1rem;
              line-height: 120%;
              max-width: 70%;
            }
          }
        }
      }

      .scrolledTextFrame {
        margin-bottom: 0vh;
      }

      .scrolledText {
        span {
          font-size: 3.5rem;
        }
      }
    }

    .real {
      .realSlider .card {
        padding-top: 5rem;

        .frame {
          > img:first-child {
            top: 1rem;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .landing {
    .real {
      .togglerFrame {
        margin-left: -5vw;
        transform: scale(0.75);
      }

      .realSlider {
        .cardFrame {
          .card {
            padding: 3rem 5vw 0;

            .frame {
              > img:first-child {
                //top: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
