@import "../variables.scss";

.weWork {
  color: $white;
  display: flex;
  flex-direction: column;

  > div {
    //max-width: 2000px;
  }

  .head {
    padding: 6rem 5rem 3rem;
    text-transform: uppercase;
    text-align: left;

    h1 {  
      font-size: 6rem;
      font-weight: 600;
      line-height: 100%;

      b {
        line-height: 80%;
        display: block;
        font-size: 10.3rem;
        font-weight: 700;
      }
    }
  }

  .bubble {
    border-radius: 1rem;
    border: 1px solid $grey900;
    padding: 1rem;
    max-width: 1500px;

    &.title {
      text-transform: uppercase;
      padding: 1rem 1.5rem 1rem;
      font-weight: 400;
      font-size: 1.15rem;
      border-radius: 1.8rem;
      min-width: 13rem;
    }
  }

  .line {
    padding: 1rem 5rem;
    border-top: 1px solid $grey900;
    border-bottom: 1px solid $grey900;
    display: flex;
    align-items: flex-start;

    > .bubble:last-child {
      font-size: 1.5rem;
      padding: 1rem 2rem;
      flex: 2;
      margin-left: 1rem;
      text-align: left;
    }
  }

  .process {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 5rem 2rem 5rem;

    .row {
      display: flex;
      padding-top: 1rem;
      flex-wrap: wrap;
      overflow: hidden;

      > .card {
        background-color: rgba($grey900, .5);
        height: 17rem;
        width: calc(25% - 1rem);
        max-width: 30rem;
        margin-right: .5rem;
        margin-bottom: .5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1.3rem 2rem;
        border-radius: 1rem;
        text-align: left;
        color: $white;

        > span {
          font-size: 1.7rem;
          font-style: italic;
        }

        > div {
          flex: 2;
          display: flex;
          align-items: flex-end;
          margin-bottom: 1.5rem;
          
          > b {
            font-size: 2.7rem;
            font-weight: 600;
            line-height: 120%;
          }
          > b > b {
            font-size: 4rem;
          }
        }

        > p {
          font-size: 1rem;
          height: 3rem;
          line-height: 130%;
        }
      }
    }
  }

  .services {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 5rem 7rem;
    border-top: 1px solid $grey900;

    .row {
      display: flex;
      padding-top: 2rem;
      width: 100%;
      max-width: 1800px;
      flex-wrap: wrap;

      > .elem {
        flex: 2;
        margin: 0 2rem 2rem 0;
        display: flex;
        //align-items: ;

        > img {
          width: 4rem;
          height: 4rem;
          border: 1px solid $white;
          border-radius: 100%;
        }

        > div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 1.5rem;
        }

        .title {
          margin-bottom: 1.5rem;
          margin-top: .9rem;
          display: flex;
          align-items: center;

          > h3 {
            font-size: 2rem;
            font-weight: 500;
            font-style: italic;
          }

          > svg {
            height: .7rem;
            width: .7rem;
            transition: $t1;
            margin-left: 1rem;
            transform: rotate(-90deg);
          }
        }

        .list {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          opacity: 0;
          transition: $t1;
          max-height: 3rem;

          > span {
            color: $grey500;
            margin-bottom: .8rem;
            font-size: 1.3rem;
            font-style: italic;
          }
        }

        &.show {
          .title {
            svg {
              transform: rotate(90deg);
            }
          }

          .list {
            opacity: 1;
            max-height: unset;
          }
        }


      }


    }
  }
}




@media (max-width: 1700px) {

  .weWork{

    .process {
      .row {
        > .card {

          > div {
            > b {
              font-size: 2.7rem;
            }
            > b > b {
              font-size: 3.5rem;
            }
          }
        }
      }
    }

  }
}


@media (max-width: 1440px) {
  .weWork {

  .head {

    h1 {  
      font-size: 4rem;

      b {
        font-size: 8rem;
      }
    }
  }
  
  .process {
    .row {
      > .card {
        height: 15rem;
        width: calc(50% - 1rem);
        max-width: 50%;
      }
    }
  }

  .services {
    .row {
      > .elem {
        flex: 1;
        margin: 0 2rem 2rem 0;
        display: flex;
        min-width: 21rem;
      }
    }
  }

  }
}


@media (max-width: 1000px) {
  .weWork {
    .head {
      padding: 5rem 2rem 3rem;
  
      h1 {  
        font-size: clamp(2rem, 5vw, 5rem);
        text-align: center;
  
        b {
          margin-top: .5rem;
          font-size: clamp(4rem, 12vw, 8rem);
          line-height: 80%;
        }
      }
    }
  
    .bubble {
      padding: .7rem;
  
      &.title {
        padding: 2vw 4vw;
        font-size: clamp(1rem, 2vw, 1.15rem);
        border-radius: 1.8rem;
        min-width: unset;
      }
    }
  
    .line {
      padding: 1rem 2rem;
      flex-direction: column;
      align-items: center;
  
      > .bubble:last-child {
        padding: 2vw 4vw;
        font-size: clamp(1.1rem, 2.3vw, 1.5rem);
        margin-left: 0;
        line-height: 130%;
        text-align: center;
        margin-top: 1rem;
      }
    }
  
    .process {
      align-items: center;
      padding: 2rem;
  
      .row {
        flex-direction: column;
        width: 100%;

        > .card {
          margin: 0;
          margin-bottom: calc(.5rem + 1vw);
          height: clamp(13rem, 50vw, 17rem);
          max-width: unset;
          width: 100%;
          align-items: flex-start;
          padding: 5vw 2rem;
  
          > span {
            font-size: 1.7rem;
            font-weight: 500;
          }
  
          > div {
            margin-bottom: 2.5rem;
            
            > b {
              font-size: 3rem;
              line-height: 100%;
            }
            > b > b {
              font-size: 3rem;
            }
          }
  
          > p {
            font-size: 1.2rem;
            height: 2.4rem;
            line-height: 100%;
          }
        }
      }
    }
  
    .services {
      align-items: center;
      padding: 2rem 2rem 7rem;
  
      .row {
        flex-direction: column;
        padding-top: 2rem;
        width: 100%;
  
        > .elem {
          flex: 1;
          margin: 0;
          margin-bottom: 1.5rem;
          display: flex;
          border: 1px solid $grey900;
          border-radius: 2rem;
          padding: clamp(1.5rem, 3vw, 3rem) clamp(2rem, 4vw, 4rem);
          //align-items: ;
  
          > img {
            width: clamp(3rem, 8vw, 4rem);
            height: clamp(3rem, 8vw, 4rem);
          }
  
          > div {
            display: flex;
            flex-direction: column;
            margin-left: 3.5rem;
            flex: 2;
            min-height: clamp(3rem, 8vw, 4rem);
          }
  
          .title {
            margin-bottom: 1.5rem;
            margin-top: .9rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
  
            > h3 {
              font-size: clamp(1.7rem, 4vw, 3rem);
            }
  
            > svg {
              height: 1rem;
              width: 1rem;
              margin-left: 0;
            }
          }
  
          .list {
            max-height: 0;
            transition: $t1;
  
            > span {
              margin-bottom: 1.2rem;
              font-size: clamp(1.2rem, 2.7vw, 2rem);
              text-align: left;
            }
          }
  
          &.show {
            .list {
              max-height: 20rem;
            }
          }
  
  
        }
      }
    }

  }
}





@media (max-width: 678px) {
  .weWork {  

    .head {
      padding-left: 1rem;
      padding-right: 1rem;
      
      h1 {
        b.smaller {
          font-size: 100%;
        }
      }
    }
    .process {
      .row {
        > .card {
          height: clamp(13rem, 53vw, 15rem);
          padding: 5vw 2rem;
  
          > span {
            font-size: 1.7rem;
          }
  
          > div {
            margin-bottom: 1.2rem;
            
            > b {
              font-size: 2.5rem;
              line-height: 90%;
            }
            > b > b {
              font-size: 2.5rem;
            }
          }
  
          > p {
            font-size: 1.1rem;
            height: auto;
          }
        }
      }
    }
  
    .services {
      .row {
        > .elem {
          max-width: 100%;
          margin-bottom: 1rem;
          padding: clamp(1rem, 2.4vw, 2rem) clamp(1.5rem, 3vw, 3rem);
  
          > img {
            margin-top: .3rem;
          }

          > div {
            margin-left: 2rem;
            min-height: clamp(2rem, 6.5vw, 4rem);
          }
  
          .title {
            margin-bottom: 1.5rem;
            margin-top: .9rem;
  
            > h3 {
              font-size: clamp(1.7rem, 4vw, 3rem);
            }
          }
  
          .list {
  
            > span {
              margin-bottom: 1.2rem;
              font-size: clamp(1.2rem, 2.7vw, 2rem);
              text-align: left;
            }
          }
        }
      }
    }

  }
}





@media (max-width: 444px) {
  .weWork {  

    .head, .line, .process, .services {
      padding-left: 0rem;
      padding-right: 0rem;
    }

    .services {

      .row {
        > .elem {
          border-radius: 1.5rem;
          //padding: 1rem 1rem;
  
          > img {
            width: 2rem;
            height: 2rem;
            margin-top: .7rem;
          }

          > div {
            margin-left: 1rem;
            min-height: clamp(2rem, 6.5vw, 4rem);
          }
  
          .title {
            margin-bottom: 1.5rem;
            margin-top: .9rem;
  
            > h3 {
              font-size: clamp(1.7rem, 4vw, 3rem);
            }
          }
  
          .list {
  
            > span {
              margin-bottom: 1.2rem;
              font-size: clamp(1.2rem, 2.7vw, 2rem);
              text-align: left;
            }
          }
        }
      }
    }

  }
}