@import "./variables.scss";
@import "./styles/menu.scss";


@font-face {
  font-family: "Aeonik";
  src:
    local("Aeonik"),
    url("./fonts/Aeonik-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src:
    local("Aeonik"),
    url("./fonts/Aeonik-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src:
    local("Aeonik"),
    url("./fonts/Aeonik-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src:
    local("Aeonik"),
    url("./fonts/Aeonik-Bold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src:
    local("Aeonik"),
    url("./fonts/Aeonik-Black.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
/*@font-face {
  font-family: "Aeonik";
  src: url("DroidSerif-Italic-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Aeonik";
  src: url("DroidSerif-Bold-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("DroidSerif-BoldItalic-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}*/

::-webkit-scrollbar {
  width: 7px;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(1, 1, 1, 0);
}

::-webkit-scrollbar-thumb {
  background: $grey500;
}

::-webkit-scrollbar-thumb:hover {
  background: $grey800;
}

.App {
  font-family: "Aeonik", Helvetica;
  text-align: center;
  background-color: $black;
  cursor: none;
}

.no-scroll {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

a,
button {
  cursor: none;
}

.custom-cursor {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  mix-blend-mode: difference;

  > div {
    position: absolute;
    width: 7px;
    height: 7px;
    bottom: 0;
    left: 50%;
    border-radius: 50%;
    z-index: 10000;

    &.cursor {
      background-color: $white;

      &.empty {
        background-color: $black;
      }
    }

    &.follower {
      border: 1px solid $white;
      width: 30px;
      height: 30px;
      transition:
        width $t1,
        height $t1;

      &.full {
        background-color: $white;
        width: 40px;
        height: 40px;

        &.forceHidden {
          display: block !important;
        }
      }
    }
  }
}

#errorPage {
  font-family: "Aeonik", Helvetica;
  text-align: center;
  background-color: $black;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;

  h1 {
    color: $grey800;
    font-size: 10rem;
    font-weight: 900;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.3rem;
    font-weight: 900;
    color: $grey500;
    margin-bottom: 1.5rem;
  }

  a {
    background-color: $white;
    color: $grey800;
    display: block;
    font-weight: 800;
    padding: 1rem 2rem;
    font-size: 1rem;
    text-transform: uppercase;
    border-radius: 2rem;
  }
}

#legal {
  color: $white;
  text-align: left;
  padding: 5rem 0;

  h1 {
    font-size: 5rem;
    padding-bottom: 3rem;
    padding-left: 5rem;
    border-bottom: 1px solid $grey900;
    margin-bottom: 1rem;
  }

  p {
    padding: 3rem 5rem;
    font-size: 1.1rem;
    max-width: 60rem;

    h2 {
      font-size: 2rem;
      margin-top: 3rem;
      margin-bottom: 1rem;
    }
  }
}

.getTouch {
  padding: 7rem 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;
  position: relative;
  overflow: hidden;

  .borderFrame {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid $grey900;
    border-radius: 20px;
  }

  .textFrame {
    color: $white;
    z-index: 2;
    max-width: 100%;
  }

  h2 {
    font-size: clamp(7rem, 10vw, 13rem); //13.7rem;
    line-height: 85%;
    text-transform: uppercase;
    text-align: left;
    font-weight: 800;
    max-width: 100%;
    //word-break: break-all;

    a {
      color: $white;
      transition: $t1;
      font-size: 99%;
      max-width: 100%;

      &:hover {
        color: $grey500;
        text-decoration: underline;
      }
    }
  }

  .smaller {
    h2 {
      font-size: clamp(5rem, 8vw, 10rem);
      line-height: 90%;
      margin-left: 5vw;
    }

    a {
      font-size: 115%;
      line-height: 83%;
    }
  }
}

.scrollDown {
  position: fixed;
  top: 0;
  right: 0;
  height: 10rem;
  width: 10rem;
  pointer-events: none;
  overflow: hidden;
  z-index: 200;

  > img {
    width: 100%;
    height: 100%;
    //animation: continuousRotate 30s linear infinite;
  }
}

.forceHidden {
  display: none !important;
}

@media (max-width: 1440px) {
  .getTouch {
    padding: 7rem 5rem;
    height: 90vh;

    .borderFrame {
      border-radius: 20px;
    }

    h2 {
      font-size: 10rem;
      line-height: 85%;
      text-align: left;
      font-weight: 800;

      a {
        font-size: 9.5rem;
      }
    }

    .smaller {
      h2 {
        font-size: 8rem;
      }
    }
  }
}

@media (max-width: 1200px) {
  .getTouch {
    h2 {
      font-size: 8rem;
      display: flex;
      flex-direction: column;

      > br:first-of-type {
        display: none;
      }

      a {
        font-size: 7.5rem;
      }
    }

    .smaller {
      h2 {
        font-size: 6.5rem;
      }

      a {
        font-size: 7rem;
      }
    }
  }
}

@media (max-width: 1000px) {
  .App {
    cursor: default;
  }
  a,
  button {
    cursor: pointer;
  }
}

@media (max-width: 900px) {
  .getTouch {
    .smaller {
      h2 {
        font-size: 5.5rem;
        margin-left: 2vw;
      }

      a {
        font-size: 6rem;
      }
    }
  }
}

@media (max-width: 750px) {
  .getTouch {
    .smaller {
      h2 {
        font-size: 5rem;
      }

      a {
        font-size: 5.5rem;
      }
    }
  }
}

@media (max-width: 678px) {
  .getTouch {
    padding: 7rem 4rem;
    min-height: 70vh;
    height: auto;

    h2 {
      text-align: center;
      font-size: 4rem;
      line-height: 100%;
      //font-weight: 500;

      a {
        margin-top: 1rem;
        font-size: 5rem;
        display: block;
        line-height: 95%;
        //height: auto;
      }

      small {
        font-size: 80%;
        line-height: 70%;
      }
    }

    .smaller {
      h2 {
        margin-left: 0;
        font-size: clamp(2rem, 10vw, 4rem);
      }

      a {
        font-size: 110%;
      }
    }
  }

  #legal {
    padding: 7rem 0;
  
    h1 {
      font-size: 4rem;
      padding-bottom: 3rem;
      padding-left: 3rem;
    }
  
    p {
      padding: 3rem 3rem;
      font-size: 1rem;
  
      h2 {
        font-size: 1.7rem;
        margin-top: 2.5rem;
        margin-bottom: 1rem;
      }
    }
  }
  
}

@media (max-width: 444px) {
  .getTouch {
    padding: 6rem 3rem;

    h2 {
      text-align: center;
      font-size: 2.7rem;
      line-height: 100%;
      //font-weight: 500;

      span.fr {
        font-size: 100%;
        line-height: 100%;
      }

      a {
        margin-top: 1rem;
        font-size: 3rem;
        line-height: 70%;
        display: block;

        span {
          font-size: 120%;
          line-height: 80%;
        }

        span.fr {
          font-size: 90%;
          line-height: 100%;
        }
      }
    }

    .smaller {
      h2 {
        font-size: 2rem;
      }

      a {
        font-size: 3rem;
      }
    }
  }
}
